import './App.css';

import Footer from './components/footer';
import Topper from './components/topper';
import Homescreen from './components/homescreen';
import Blog from './components/blog/blog';

import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import Post from './components/blog/post';
import Content from './components/blog/content';
import NotFound from "./components/notfound"
import Privacy from './components/privacy';
import { HelmetProvider } from 'react-helmet-async';

function App() {

  const helmetContext = {};

  const router = createBrowserRouter([
    {
      path: "*",
      element: (<NotFound/>)
    },
    {
      path: "/",
      errorElement: <NotFound />,
      element: (<Homescreen />),
    },
    {
      path: "/privacy",
      errorElement: <NotFound />,
      element: (<Privacy/>),
    },
    {
      path: "/blogs",
      errorElement: <NotFound />,
      element: <Blog />,
      children: [
        {
          path: "/blogs",
          element: <Content />
        },
        {
          path: "/blogs/:id",
          element: <Post props={":id"} />,
        }
      ]

    },

  ]);
  return (
    <>
        <Topper />
        <HelmetProvider context={helmetContext}>
        <RouterProvider router={router} />
        </HelmetProvider>
        <Footer />
    </>
  );
}

export default App;
