import React from "react"
import Header from "../components/blog/header"
import Menu from "./menu";

export default function Privacy() {

    window.scrollTo(0, 0)

    return (
        <>
        <Menu />
            <Header text="Privātuma politika" />
            <div id="content" className="content">
                <div className="posts">
                    <div className="post" id="privacy">
                        <p>
                            Šī privātuma politika informē par privātuma praksi un personas datu apstrādes principiem saistībā ar saimnieciskās darbības veicējas Sandras Jurbergas-Šaudines reģ.nr. 10109201802 zīmola 'Frogit' mājas lapām un pakalpojumiem. Šī privātuma politika attiecas tikai uz šādās mājas lapās iegūto informāciju: <a href="https://frogit.lv" title="Frogit mājas lapa">https://frogit.lv</a> , <a href="https://minidigital.frogit.lv" title="MiniDigital projekts">https://minidigital.frogit.lv</a> un <a href="https://rekinipats.frogit.lv" title="Rēķini pats">https://rekinipats.frogit.lv</a>. Lai sazinātos saistībā ar datu apstrādes jautājumiem, lūdzu rakstiet e-pastu uz info@frogit.lv.
                        </p>
                        <h1>Kādu informāciju mēs iegūstam?</h1>
                        <p>Mēs iegūstam tādus personas datus, ko jūs mums brīvprātīgi sniedzat ar e-pasta starpniecību, aizpildot tīmeklī bāzētās anketas un citā tiešā saziņā ar jums.
                            Iesniedzot jautājumu, jums jānorāda vārds, e-pasts un cita informācija kuru jūs vēlaties sniegt. Lapās var tik izvietoti ievades lauki, kur jūs brīvprātīgi varat atstāt savu e-pasta adresi tālākai saziņai. Pierakstoties sistēmas lietošanai <a href="https://minidigita.frogit.lv" title="MiniDigital projekts">https://minidigital.frogit.lv</a> Jūs sniedzat informāciju par sevi, norādot vārdu, uzvārdu, e-pastu un lietotā domēna vārdu, kā unikālu identifikatoru jūsu komercijai.
                        </p>
                        <h1>Kā mēs izmantojam iegūtos personas datus?</h1>
                        <p>Mēs varam izmantot iegūtos personas datus, lai</p>
                        <ul>
                            <li>* sniegtu jums jūsu pieprasītos pakalpojumus un informāciju,</li>
                            <li>* apstrādātu jūsu pasūtījumus un noformētu nepieciešamos dokumentus,</li>
                            <li>* sniegtu jums efektīvu klientu atbalstu,</li>
                            <li>* palīdzētu novērst apdraudējumu vai krāpnieciskas darbības,</li>
                            <li>* nosūtītu jums informatīvus ziņojumus, ja esat nepārprotami piekrituši tādus saņemt,</li>
                            <li>* ievērotu normatīvo aktu prasības,</li>
                            <li>* Lai nodrošinātu Jūsu mājas lapas satura attēlošanu.</li>
                        </ul>
                        <p>Mēs varam nodot jūsu informāciju trešajām personām, lai ievērotu normatīvo aktu prasības, sadarbotos ar uzraudzības iestādēm, palīdzētu novērstu noziedzīgas darbības un aizsargātu mūsu, jūsu un citu personu likumīgās tiesības.
                        </p>
                        <h1>Kā mēs aizsargājam personas datus?

                        </h1>
                        <p>Jūsu personas datu aizsardzībai mēs izmantojam dažādus tehniskus un organizatoriskus drošības pasākumus. Jūsu personas dati ir pieejami ierobežotam cilvēku skaitam, tikai pilnvarotām personām.</p>
                        <h1>Cik ilgi mēs glabājam personas datus?</h1>
                        <p>Mēs glabājam jūsu personas datus tik ilgi, cik ilgi tie ir mums nepieciešami atbilstoši to ieguves mērķim un kā to pieļauj vai nosaka normatīvo aktu prasības.Visus sistēmā ievadītos datus uzglabājam tik ilgi, cik ilgi tas nepieciešams Jums. Izvēloties tos dzēst, atlasītā informācija ties izdzēsta no datubāzes.Lietotāja profila informācija tiek uzglabāta bez termiņa, ja east reģistrējies sistēmā cour reģistrācijas saiti, turpmāk administratora lietotājs .Dati itek dzēsti pēc lietotāja lūguma. Jebkuras citas personas reģistrēšana sistēmā, kura varēs pārvaldīt administratora lietotāja norādītā domēnvārda mājas lapas saturu ir administratora lietotāja atbildība, un dati no datubāzes tiek dzēsti pēc administratora lietotāja “dzēst” operācijas izpildes.</p>
                        <h1>Kā mēs izmantojam sīkdatnes?</h1>
                        <p>Sīkdatnes ir nelielas teksta datnes, ko jūsu apmeklētās mājas lapas saglabā jūsu datorā. Tās tiek izmantotas, lai nodrošinātu mājas lapas darbību, kā arī lai sniegtu informāciju vietnes īpašniekiem.</p>
                        <p>Šī mājas lapa var iestatīt sekojošas sīkdatnes:</p>
                        <ul>
                            <li>*Funkcionālās sīkdatnes. Šīs sīkdatnes ir nepieciešamas, lai jūs spētu pārvietoties mājas lapā un lietot tās funkcijas. Bez šim sīkdatnēm mēs nevaram nodrošināt jūsu pieprasītos pakalpojumus, piemēram pierakstīšanos sistemā.</li>
                            <li>* Google Analytics sīkdatnes. Šīs sīkdatnes lieto, lai iegūtu mūsu mājas lapas apmeklējuma statistiku. Mēs lietojam šo informāciju, lai uzlabotu vietnes darbību un reklāmas pasākumus.</li>
                        </ul>
                        <h1>Kā atteikties no sīkdatnēm?</h1>
                        <p>Lai atteiktos no sīkdatņu saņemšanas, jūs varat izmantojot privātās pārlūkošanas režīmu, kuru nodrošina lielākā daļa pārlūkprogrammu (privāts logs, inkognito logs vai InPrivate logs). Jebkādas sīkdatnes, kas tiek izveidotas, darbojoties privātās pārlūkošanas režīmā, tiek dzēstas, tiklīdz jūs aizverat visus pārlūka logus.</p>
                        <h1>Google Analytics</h1>
                        <p>Mēs izmantojam Google Analytics rīku, lai saprastu, kā tiek lietota vietne, un lai uzlabotu lietotāju pieredzi. Lietotāja dati ir pilnībā anonīmi. Jūs varat uzzināt vairāk par Google nostāju attiecībā uz privātumu saistībā ar tā analītikas pakalpojumiem, apmeklējot vietni www.google.com.
                            Mēs varam izmantot Google Analytics Demogrāfijas un interešu ziņošanas funkcijas, lai apskatītu anonīmu statistiku par mūsu apmeklētāju demogrāfiju un interesēm.
                            Lai atspējotu šādas darbības, instalējiet Google Analytics pārlūka nepiedalīšanās iespējas pievienojumprogrammu (Google Analytics Opt-out Browser Add-on).</p>
                    <h1>Jūsu tiesības saistībā ar jūsu personas datiem</h1>
                    <p>Ja jūs esat datu subjekts saskaņā ar ES VDAR (piemēram, jūs esat ES pilsonis un sniedzat mums savus personas datus), jums ir turpmāk minētās tiesības saistībā ar saviem personas datiem:</p>
                    <ul>
                        <li>* Tiesības piekļūt informācijai. Jums ir tiesības saņemt informāciju par to, kāpēc un kā tiek apstrādāti jūsu personas dati. Jums ir tiesības bez maksas saņemt mūsu rīcībā esošo jūsu personas datu kopiju plaši izmantotā elektroniskā formātā.</li>
                   <li>* Tiesības labot. Jums ir tiesības panākt neprecīzu vai nepilnīgu personas datu labošanu vai papildināšanu bez nepamatotas kavēšanās.</li>
                   <li>* Tiesības „tikt aizmirstam”. Jums ir tiesības atsaukt savu piekrišanu personas datu apstrādei un panākt savu personas datu dzēšanu bez nepamatotas kavēšanās, tiklīdz dati vairs nav nepieciešami, lai sniegtu jūsu pieprasītos pakalpojumus un ievērotu normatīvo aktu prasības.</li>
                    <li>* Tiesības ierobežot apstrādi. Jums ir tiesības panākt savu personas datu apstrādes ierobežošanu, ja jūs iebilstat pret to un mums nav leģitīmu pamatu turpināt apstrādi, ja jūs apstrīdat datu precizitāti, ja apstrāde ir pretlikumīga vai ja jūs pieprasāt celt, īstenot vai aizstāvēt likumīgas prasības.</li>
                   <li>* Tiesības iebilst. Jums ir tiesības jebkurā brīdī iebilst pret datu apstrādi, ja vien tas nav nepieciešams sabiedrības interesēs veicamam uzdevumam vai apstrādei nepastāv neapstrīdami likumīgs pamats.</li>
                   <li>* Citas tiesības saskaņā ar VDAR. Vairāk informācijas skatiet, apmeklējot ES datu aizsardzībai veltīto mājas lapu.</li>
                    </ul>
                    </div>
                </div>
            </div>
        </>
    )
}  
