import React from "react"
import logo from "../components/assets/baltslogo.png"

export default function Footer() {
    return (
        <footer className="footer">
            <div className="column">
            <p>Copyright 2025 by frogit.lv. All Rights Reserved.</p>
            <p><a href="/privacy" title="Privātuma politika">Privātuma politika</a></p>
            <img src={logo} alt="logo ar robotizētu vardi" title="Frogit logo" />
            <p>info@frogit.lv</p>
        </div>
        </footer >
    )
}